import clsx from "clsx"

import type { PropsWithClassName } from "~/@types/generics"
import { ClientTranslate } from "~/providers/I18nProvider/ClientTranslate"
import type { TCart } from "~/managers/CartManager/_data/serializer"

import { text } from "~/styles/variants"

import * as css from "./styles.css"

export type EmbroideryPictoProps = PropsWithClassName<{
  value: string
  count: 1 | 2
  pictos: NonNullable<TCart>["globalEmbroideryPictos"]
}>

function EmbroideryPicto({ className, value, count, pictos }: EmbroideryPictoProps) {
  if (value === "none") return null
  const label = pictos?.find((picto) => picto?.value === value)?.text ?? value

  return (
    <li className={clsx(css.Embroidery, className)}>
      <ClientTranslate
        as="span"
        tKey="embroidery_picto"
        className={text({
          design: "riposte-13",
          color: "blue-light-txt",
        })}
      >
        {" "}
        {count}
      </ClientTranslate>
      <span
        className={text({
          design: "riposte-13",
          color: "blue-deep",
        })}
      >
        {" : " + label}
      </span>
    </li>
  )
}

export default EmbroideryPicto
