import { useQuery } from "@tanstack/react-query"

import type { GID } from "@unlikelystudio/commerce-connector"

import { useLocale } from "~/lib/i18n/hooks/useLocale"
import { getCrossSellProductCards } from "~/components/globals/Cart/components/CrossSell/hooks/get"

export function useCrossSellProductCardsQuery(ids: GID[]) {
  const locale = useLocale()

  return useQuery({
    queryKey: ["useCrossSellProductCardsQuery", ids],
    queryFn: async () => getCrossSellProductCards(ids, locale),
    enabled: !!ids.length,
  })
}
