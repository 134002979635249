import type { Dictionary } from "~/providers/I18nProvider/constants"

type labelProps = {
  label: keyof Dictionary
}

type PlacementDbProps = {
  none: labelProps
  center: labelProps
}

export const PLACEMENTS_DB: PlacementDbProps = {
  none: {
    label: "embroidery_placement_top_left",
  },
  center: {
    label: "embroidery_placement_center",
  },
}
