import clsx from "clsx"

import type { PropsWithClassName } from "~/@types/generics"
import { PLACEMENTS_DB } from "~/components/ui/Product/ProductHeader/PanelEmbroidery/EmbroideryForm/EmbroideryPlacementSelector/constants"
import { ClientTranslate } from "~/providers/I18nProvider/ClientTranslate"
import { useTranslate } from "~/providers/I18nProvider/hooks/useTranslate"

import { text } from "~/styles/variants"

export type EmbroideryPlacementProps = PropsWithClassName<{
  placement: keyof typeof PLACEMENTS_DB
}>

function EmbroideryPlacement({ className, placement }: EmbroideryPlacementProps) {
  const t = useTranslate()

  if (placement === "none") return null
  const label = PLACEMENTS_DB[placement].label ?? placement

  return (
    <ClientTranslate
      as="li"
      tKey="embroidery_placement"
      className={clsx(
        className,
        text({
          design: "riposte-10",
          color: "blue-light",
        })
      )}
    >
      {` : ${t(label)}`}
    </ClientTranslate>
  )
}

export default EmbroideryPlacement
